<template>
  <div class="empty-list">
    <h4 v-if="title" class="empty-list__h" v-html="title" />

    <p v-if="description" class="empty-list__p" v-html="description" />

    <CButton
      color="primary"
      variant="outline"
      class="empty-list__button"
      @click="button.route()"
    >
      {{ button.text }}
    </CButton>
  </div>
</template>

<script>
export default {
  name: 'EmptyOptionsList',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    button: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>

<style scoped lang="scss">
.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 27px 0;

  &__h {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #1c262f;
    margin: 0;
  }

  &__p {
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 0;
    color: #677a89;
  }

  &__button {
    margin-top: 24px;
    width: auto;
  }
}
</style>
