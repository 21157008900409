<template>
  <ul class="dynamic-dropdowns-list">
    <template v-if="items.length">
      <li
        v-for="(item, index) in items"
        :key="'item-' + index + item.value"
        class="dynamic-dropdowns-list__item"
        :class="{
          'dynamic-dropdowns-list__item--no-options': !setOptions(item).length,
        }"
      >
        <CNSelect
          :model-value="item.value"
          :options="setOptions(item)"
          label="Select Collaborator"
          class="dynamic-dropdowns-list__select"
          :invalid="checkErrors(item)"
          :error="checkErrors(item)"
          :information-text="informationTextForNotMandatoryField(item)"
          :disabled="disabled"
          @change="emitChange($event, item)"
        >
          <template #nooptions>
            <EmptyOptionsList
              :description="`You have not assigned a(n) ${item.positionName} for your project. <br/> Please go back to team management to add a(n) ${item.positionName}.`"
              :button="{
                text: 'Go to Team Management',
                route: () =>
                  $router.push({
                    name: 'ProjectTeams',
                    params: { id: $route.params.id },
                  }),
              }"
            />
          </template>
        </CNSelect>

        <button
          v-if="!item.mandatory && items.length > 1"
          class="dynamic-dropdowns-list__delete-button"
          type="button"
          @click="deleteItem(index)"
        >
          <CIcon name="cilX" size="lg" class="dynamic-dropdowns-list__icon" />
        </button>
      </li>
    </template>

    <li
      v-if="!(items.length && withoutAdditionalFields)"
      class="dynamic-dropdowns-list__item"
    >
      <CNSelect
        v-model="newField"
        :options="options"
        label="Select Collaborator"
        class="dynamic-dropdowns-list__select"
        :disabled="disabled"
        :required="!items.length"
        @change="emitChange"
      />
    </li>
  </ul>
</template>

<script>
import EmptyOptionsList from './EmptyOptionsList'
import { notifyByPlan } from '@/services/Notify/Toasts'

export default {
  name: 'DynamicDropdownsList',
  components: { EmptyOptionsList },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    withoutAdditionalFields: { type: Boolean, default: false },
  },
  emits: ['change'],
  data() {
    return {
      newField: null,
    }
  },
  methods: {
    checkErrors(item) {
      const optionsLength = this.setOptions(item)?.length

      if (!item.mandatory || item.value) return

      return optionsLength
        ? `There are multiple ${item.positionName}(s) selected on the Project. Please select one person to approve`
        : `A ${item.positionName} is required to approve this scope of work`
    },
    informationTextForNotMandatoryField(item) {
      const optionsLength = this.setOptions(item)?.length

      if (item.mandatory || item.value) return

      return item.settingId && optionsLength
        ? `There are multiple ${item.positionName}(s) selected on the Project. Please select one person to approve`
        : `There are no ${item.positionName}(s) on the project team`
    },
    setOptions(item) {
      if (item.settingId && item.positionName) {
        const _options = this.options.filter(
          (el) => el.positionName === item.positionName,
        )

        if (!item.value) return _options
        else return [item, ..._options]
      } else {
        if (!item.value) return this.options
        else return [item, ...this.options]
      }
    },
    deleteItem(index) {
      if (!this.canDelete) {
        notifyByPlan()
        return
      }
      this.$emit('change', [...this.items.filter((el, idx) => index !== idx)])
    },
    emitChange(value, item) {
      if (!value) return

      if (value === item?.value) return

      const user = this.options?.filter((el) => el.value === value)[0]

      if (item) {
        if (item.settingId) {
          const data = this.items.map((el) =>
            el?.settingId === item?.settingId ? { ...el, ...user } : { ...el },
          )
          this.$emit('change', data)
        } else {
          const data = this.items.map((el) =>
            el?.value === item?.value ? { ...el, ...user } : { ...el },
          )
          this.$emit('change', data)
        }
      } else {
        this.$emit('change', [
          ...this.items,
          {
            ...user,
          },
        ])

        this.$nextTick(() => {
          this.newField = null
        })
      }
    },
  },
}
</script>

<style lang="scss">
.dynamic-dropdowns-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    position: relative;
    margin-top: 16px;
    padding-right: 27px;

    &--no-options {
      .multiselect-dropdown {
        max-height: 227px;
      }
    }
  }

  &__delete-button {
    position: absolute;
    top: 18px;
    right: 0;
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
  }

  &__icon {
    color: #db000b;
  }
}
</style>
